import { gql } from "@apollo/client";

import {
  addAlertEmailNotification,
  addAlertSubscription,
  addAlertWildCardSubscription,
  addAnnotation,
  addCategory,
  addClient,
  addClientGroup,
  addCustomer,
  addCustomerDTObject,
  addDTObject,
  addDTProject,
  addDevice,
  addDeviceMake,
  addDomain,
  addLicensePlate,
  addLocation,
  addNode,
  addService,
  addTag,
  addVideo,
  addZone,
  addZoneThreshold,
  assignFirstRoleToUser,
  batchAddCategory,
  batchAddLicensePlate,
  batchAddTagAssociations,
  batchAddVideos,
  batchDeleteVideos,
  createSecretsManagerSecret,
  changeNodeStatus,
  deleteAlert,
  deleteAllAnnotations,
  deleteAnnotation,
  deleteCategory,
  deleteClient,
  deleteClientGroup,
  deleteCustomerDTObject,
  deleteDTProject,
  deleteDevice,
  deleteDeviceMake,
  deleteLicensePlate,
  deleteLocation,
  deleteNode,
  deleteService,
  deleteTag,
  deleteUser,
  deleteVideo,
  deleteZone,
  deleteZoneThreshold,
  overruleHumanValidatedEvents,
  publishNode,
  setIsRunningServiceStatus,
  setPendingState,
  syncShadow,
  takeEventToValidate,
  updateAdminEmailAlert,
  updateAdminSystemAlert,
  updateAlert,
  updateAlertStatus,
  updateAnnotation,
  updateCategory,
  updateClient,
  updateClientGroup,
  updateCustomer,
  updateCustomerDTObject,
  updateDTProject,
  updateDevice,
  updateDeviceMake,
  updateLicensePlateWithNewCategory,
  updateLocation,
  updateProfile,
  updateService,
  updateShadowNode,
  updateUser,
  updateUserAlert,
  updateZone,
  updateZoneThresholdItem,
  updateZoneThresholdValue,
  uploadDTFile,
  uploadDTFiles,
  validateEvent,
} from "../../graphql/mutations";

export const ADD_CUSTOMER = gql`
  ${addCustomer}
`;

export const ADD_DOMAIN = gql`
  ${addDomain}
`;

export const ADD_LOCATION = gql`
  ${addLocation}
`;

export const ADD_NODE = gql`
  ${addNode}
`;

export const DELETE_NODE = gql`
  ${deleteNode}
`;

export const DELETE_TAG = gql`
  ${deleteTag}
`;

export const ADD_SERVICE = gql`
  ${addService}
`;

export const ADD_DEVICE = gql`
  ${addDevice}
`;

export const DELETE_DEVICE = gql`
  ${deleteDevice}
`;

export const DELETE_LICENSE_PLATE = gql`
  ${deleteLicensePlate}
`;

export const DELETE_LOCATION = gql`
  ${deleteLocation}
`;

export const ADD_ALERT_EMAIL_NOTIFICATION = gql`
  ${addAlertEmailNotification}
`;

export const ADD_DEVICE_MAKE = gql`
  ${addDeviceMake}
`;

export const UPDATE_DEVICE_MAKE = gql`
  ${updateDeviceMake}
`;

export const DELETE_DEVICE_MAKE = gql`
  ${deleteDeviceMake}
`;

export const OVERRULE_HV_EVENTS = gql`
  ${overruleHumanValidatedEvents}
`;

export const PUBLISH_NODE = gql`
  ${publishNode}
`;

export const CHANGE_NODE_STATUS = gql`
  ${changeNodeStatus}
`;

export const UPDATE_PROFILE = gql`
  ${updateProfile}
`;

export const VALIDATE_EVENT = gql`
  ${validateEvent}
`;

export const TAKE_EVENT_TO_VALIDATE = gql`
  ${takeEventToValidate}
`;

export const SET_PENDING_STATE = gql`
  ${setPendingState}
`;

export const UPDATE_ZONE = gql`
  ${updateZone}
`;

export const UPDATE_ANNOTATION = gql`
  ${updateAnnotation}
`;

export const UPDATE_ALERT = gql`
  ${updateAlert}
`;

export const UPDATE_ALERT_STATUS = gql`
  ${updateAlertStatus}
`;

export const UPDATE_ADMIN_EMAIL_ALERT = gql`
  ${updateAdminEmailAlert}
`;

export const UPDATE_ADMIN_SYSTEM_ALERT = gql`
  ${updateAdminSystemAlert}
`;

export const UPDATE_USER_ALERT = gql`
  ${updateUserAlert}
`;

export const DELETE_ZONE = gql`
  ${deleteZone}
`;

export const DELETE_ZONE_THRESHOLD_ITEM = gql`
  ${deleteZoneThreshold}
`;

export const DELETE_ANNOTATION = gql`
  ${deleteAnnotation}
`;

export const ADD_ANNOTATION = gql`
  ${addAnnotation}
`;

export const ADD_ALERT_SUBSCRIPTION = gql`
  ${addAlertSubscription}
`;

export const ADD_ALERT_WILDCARD_SUBSCRIPTION = gql`
  ${addAlertWildCardSubscription}
`;

export const ADD_ZONE = gql`
  ${addZone}
`;

export const ADD_TAG = gql`
  ${addTag}
`;

export const DELETE_ALL_ANNOTATIONS = gql`
  ${deleteAllAnnotations}
`;

export const UPDATE_SHADOW = gql`
  ${updateShadowNode}
`;

export const ADD_DT_PROJECT = gql`
  ${addDTProject}
`;

export const DELETE_DT_PROJECT = gql`
  ${deleteDTProject}
`;

export const UPDATE_DT_PROJECT = gql`
  ${updateDTProject}
`;

export const UPDATE_DEVICE = gql`
  ${updateDevice}
`;

export const UPDATE_LOCATION = gql`
  ${updateLocation}
`;

export const UPDATE_USER_ROLE = gql`
  ${updateUser}
`;

export const DELETE_USER = gql`
  ${deleteUser}
`;

export const ASSIGN_FIRST_ROLE_TO_USER = gql`
  ${assignFirstRoleToUser}
`;

export const DELETE_SERVICE = gql`
  ${deleteService}
`;

export const UPDATE_SERVICE = gql`
  ${updateService}
`;

export const SET_RUNNING_SERVICE_STATUS = gql`
  ${setIsRunningServiceStatus}
`;

export const ADD_DT_OBJECT = gql`
  ${addDTObject}
`;

export const ADD_CUSTOMER_DT_OBJECT = gql`
  ${addCustomerDTObject}
`;

export const UPDATE_CUSTOMER_DT_OBJECT = gql`
  ${updateCustomerDTObject}
`;

export const DELETE_CUSTOMER_DT_OBJECT = gql`
  ${deleteCustomerDTObject}
`;

export const UPLOAD_DT_FILE = gql`
  ${uploadDTFile}
`;

export const UPLOAD_DT_FILES = gql`
  ${uploadDTFiles}
`;

export const SYNC_SHADOW = gql`
  ${syncShadow}
`;

export const UPDATE_CUSTOMER = gql`
  ${updateCustomer}
`;

export const ADD_CATEGORY = gql`
  ${addCategory}
`;

export const ADD_CLIENT = gql`
  ${addClient}
`;

export const ADD_ZONE_THRESHOLD = gql`
  ${addZoneThreshold}
`;

export const ADD_CLIENT_GROUP = gql`
  ${addClientGroup}
`;

export const ADD_VIDEO_DETAILS = gql`
  ${addVideo}
`;

export const BATCH_ADD_VIDEOS_DETAILS = gql`
  ${batchAddVideos}
`;

export const BATCH_DELETE_VIDEOS = gql`
  ${batchDeleteVideos}
`;

export const DELETE_VIDEO = gql`
  ${deleteVideo}
`;

export const DELETE_CLIENT_GROUP = gql`
  ${deleteClientGroup}
`;

export const DELETE_CLIENT = gql`
  ${deleteClient}
`;

export const DELETE_ALERT = gql`
  ${deleteAlert}
`;

export const UPDATE_CATEGORY = gql`
  ${updateCategory}
`;

export const UPDATE_CLIENT = gql`
  ${updateClient}
`;

export const UPDATE_ZONE_THRESHOLD_ITEM = gql`
  ${updateZoneThresholdItem}
`;

export const UPDATE_ZONE_THRESHOLD = gql`
  ${updateZoneThresholdValue}
`;

export const UPDATE_CLIENT_GROUP = gql`
  ${updateClientGroup}
`;

export const ADD_LICENSE_PLATE = gql`
  ${addLicensePlate}
`;

export const BATCH_ADD_LICENSE_PLATE = gql`
  ${batchAddLicensePlate}
`;

export const DELETE_CATEGORY = gql`
  ${deleteCategory}
`;

export const UPDATE_LICENSE_PLATE_WITH_NEW_CATEGORY = gql`
  ${updateLicensePlateWithNewCategory}
`;

export const BATCH_ADD_CATEGORY = gql`
  ${batchAddCategory}
`;

export const BATCH_ADD_TAG_ASSOCIATIONS = gql`
  ${batchAddTagAssociations}
`;

export const CREATE_SECRETS_MANAGER_SECRET = gql`
  ${createSecretsManagerSecret}
`;
